<template>
  <div id="divHealthWarningsWrapper" class="hw-wrapper"><div v-html="content"></div></div>
</template>
<script>
import StaticContent from '@/services/Api/staticContents';
export default {
  name: 'RegulationDetails',
  data() {
    return { content: '' };
  },
  created() {
    StaticContent.getStaticContent('SaglikUyarilari').then(res => {
      let {
        Data: { content },
      } = res.data;
      this.content = content;
    });
  },
};
</script>
<style scoped lang="scss">
.hw-wrapper {
  text-align: center;
  padding: palette-space-level(10);
  white-space: pre-line;

  /deep/ .img-thumbnail {
    border: none !important;
    border-radius: palette-space-level(3);
  }
}
</style>
